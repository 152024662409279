@tailwind base;
@tailwind components;
@tailwind utilities;

@import "./font-definitions.css";

@layer components {
  .wrapper-layout {
    @apply absolute bottom-0 h-full w-screen overflow-y-auto lg:h-[calc(100vh-80px)];
  }

  .app-btn {
    @apply relative z-[0] cursor-pointer overflow-hidden rounded-[40px] border border-white transition duration-300 before:absolute before:inset-0 before:z-[-1] before:origin-left before:scale-x-0 before:bg-white before:transition before:duration-300 before:ease-out;
  }

  .socialLogo {
    background: linear-gradient(to left, transparent 50%, white 50%) right;
    background-size: 200% 100%;
    transition: 0.3s ease-out;
    border-radius: 100%;
  }

  .socialLogo:hover {
    background-position: left;
  }

  .expanded-menu-link {
    @apply lg:text-hover-fill font-serif text-[25px] leading-[48px] before:text-gray-400 h700:leading-[66px];
  }

  .form-chk {
    @apply h-[15px] w-[15px] cursor-pointer border border-black bg-transparent text-black transition focus:outline-none focus:ring-0 focus:ring-offset-0;
  }
  .form-chk.inverted {
    @apply border-white text-black checked:focus:border-white hover:border-white/50 checked:hover:border-white/50;
  }

  .header-middle-link {
    @apply text-hover-fill relative text-white/50 
    transition before:text-white;
  }
  .header-middle-link.active::before {
    clip-path: polygon(0 100%, 0 0, 100% 0, 100% 100%);
  }

  .header-middle-link-mobile .header-middle-link.active {
    @apply after:absolute after:-bottom-3 after:left-1/2 after:hidden after:h-2 after:w-2 after:-translate-x-1/2 after:rounded-full after:bg-white sm:after:block;
  }

  .header-right-link {
    @apply px-[22px] py-2 text-header-link;
  }

  .newsletter-input {
    @apply w-full rounded-[76px] border border-white bg-transparent pl-5 pr-12 placeholder:text-black/50 focus:border-black focus:outline-none focus:ring-0 focus:ring-offset-0;
  }

  .footer-newsletter-input {
    @apply w-full rounded-[76px] border border-white bg-transparent pl-5 pr-12 placeholder:text-white/50 focus:border-white focus:outline-none focus:ring-0 focus:ring-offset-0;
  }
  .footer-newsletter-input.inverted {
    @apply border-black placeholder:text-black/50 focus:border-black;
  }

  .search-input {
    @apply h-[46px] w-full rounded-[76px] border border-white bg-transparent pl-[21px] pr-12 text-sm text-white placeholder:text-white/50 focus:border-white focus:outline-none focus:ring-0 focus:ring-offset-0;
  }

  .search-suggestion {
    @apply px-[22px] py-[5px] text-[12px] leading-[20px];
  }

  .om-oss-sequence {
    @apply font-serif text-[90px] leading-[85%];

    letter-spacing: 0.01em;
  }

  .marker {
    @apply h-12 w-12;
  }
}

@layer utilities {
  .app-h2 {
    @apply font-serif text-app-h3 lg:text-app-h2;

    letter-spacing: 0.01em;
  }

  .app-h3 {
    @apply font-serif text-app-h4 lg:text-app-h3;

    letter-spacing: 0.01em;
  }

  .app-h4 {
    @apply font-serif text-app-h4;

    letter-spacing: 0.01em;
  }

  .title-article {
    @apply font-serif text-title-article-m lg:text-title-article;

    letter-spacing: 0.01em;
  }

  .title-newsletter-sm {
    @apply font-serif text-[18px] leading-[22px];

    letter-spacing: 0.01em;
  }

  .absolute-center {
    @apply absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2;
  }

  .absolute-center-x {
    @apply absolute left-1/2 -translate-x-1/2;
  }

  .absolute-center-y {
    @apply absolute top-1/2 -translate-y-1/2;
  }

  .animated-media-link {
    @apply relative flex items-center justify-between px-4 py-3 transition duration-300;
  }

  .no-scroll {
    @apply overflow-hidden;
  }

  .right-word {
    @apply font-sans tracking-[0.1em];
  }

  .text-shadow {
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .wrapper-full {
    @apply relative px-6 md:px-12;
  }

  .wrapper {
    @apply wrapper-full max-w-[1440px] lg:mx-auto;
  }

  .wrapper-sm {
    @apply wrapper-full relative max-w-[1066px] lg:mx-auto xl:px-0;
  }

  .wrapper-article {
    @apply wrapper-full max-w-[882px] md:mx-auto;
  }

  .wysiwyg:not(.content-text) a {
    @apply hover:underline;
  }

  .wysiwyg p + p,
  .wysiwyg p + ul,
  .wysiwyg ul + p {
    @apply mt-4;
  }

  .wysiwyg h2 {
    @apply app-h2;
  }

  .wysiwyg h3 {
    @apply app-h3;
  }

  .wysiwyg.extra h3 {
    @apply app-h2;
  }

  .wysiwyg h4 {
    @apply app-h3;
  }

  .wysiwyg section:not(:first-of-type),
  .wysiwyg h2 + p,
  .wysiwyg h3 + p,
  .wysiwyg h4 + p,
  .wysiwyg h2 + ul,
  .wysiwyg h3 + ul,
  .wysiwyg h4 + ul {
    @apply mt-7;
  }

  .wysiwyg p + h2,
  .wysiwyg p + h3,
  .wysiwyg p + h4,
  .wysiwyg ul + h2,
  .wysiwyg ul + h3,
  .wysiwyg ul + h4 {
    @apply mt-12 lg:mt-16;
  }

  .wysiwyg figure + figure,
  .wysiwyg figure + p {
    @apply mt-12;
  }

  .wysiwyg figure > blockquote {
    @apply font-serif text-[18px] leading-[21px] md:text-[24px] md:leading-[29px];

    letter-spacing: 0.01em;
  }

  .wysiwyg figure > figcaption {
    @apply mt-2 text-[12px] leading-[19px] before:content-["-_"];
  }

  .wysiwyg ul {
    @apply list-none space-y-4;
  }

  .wysiwyg ul.two-column {
    @apply grid gap-4 space-y-0 sm:grid-cols-2;
  }

  .wysiwyg ul > li {
    @apply relative pl-4 before:absolute before:left-0 before:top-0  before:content-['–'];
  }

  .cookie-banner .wysiwyg a {
    @apply opacity-60 hover:opacity-100;
  }

  .content-text a {
    @apply text-inherit underline decoration-inherit decoration-1 transition-all focus:decoration-transparent hover:decoration-transparent;
  }
  .content-text.newsletter-body a {
    @apply after:border-black;
  }

  .cards-grid-3 {
    @apply grid sm:grid-cols-2 sm:gap-6 lg:grid-cols-3 lg:gap-x-11 lg:gap-y-12;
  }

  .text-hover-fill {
    @apply before:absolute-center relative transition before:w-full before:transition-all before:duration-300 before:ease-out before:content-[attr(data-content)];
  }
  .text-hover-fill::before {
    clip-path: polygon(0 100%, 0 0, 0 0, 0 100%);
  }
  .text-hover-fill:hover::before {
    clip-path: polygon(0 100%, 0 0, 100% 0, 100% 100%);
  }

  .swiper-nav-link {
    @apply text-white/50 transition-colors duration-500 ease-in-out;
  }
  .swiper-nav-link.active {
    @apply text-white;
  }

  .swiper-slide.swiper-slide-active .swiper-menu-item {
    @apply relative text-white;
  }

  .swiper-header {
    @apply after:absolute after:bottom-1 after:left-1/2 after:h-2 after:w-2 after:-translate-x-1/2 after:rounded-full after:bg-white sm:after:hidden;
  }

  #CybotCookiebotDialogBodyButtonsWrapper .CybotCookiebotDialogBodyButton {
    border-radius: 40px;
  }
  #CybotCookiebotDialogNav .CybotCookiebotDialogNavItemLink.CybotCookiebotDialogActive {
    border-top: none;
    border-left: none;
    border-right: none;
  }

  /* For devices that doesn't support hover like desktop */
  @media (hover: hover) {
    .app-btn {
      @apply disabled:pointer-events-none hover:text-[#1C3240] hover:before:scale-x-105;
    }
    .animated-media-link {
      @apply before:absolute before:inset-0 before:z-0 before:w-full before:origin-left before:scale-x-0 before:bg-white before:transition-all before:duration-700 hover:text-[#1C3240] hover:before:scale-x-100;
    }
  }

  /* For devices that doesn't support hover like mobile */
  @media (hover: none) {
    .rounded-article-card {
      transform: translateZ(0) !important;
    }
  }

  .debug {
    @apply border border-red-500;
  }
}

@layer base {
  html {
    height: stretch;
    height: -webkit-stretch;
  }

  body {
    @apply font-sans text-white;

    /* follow design: default line height is 23px instead of 24px :) */
    font-size: 14px;
    line-height: 21px;
  }

  #CookiebotWidget {
    display: none !important;
  }

  .generic-content table {
    @apply mt-6 w-full table-fixed border-collapse border-spacing-0;
  }
  .generic-content table tr {
    @apply mb-2 table-row;
  }
  .generic-content table tr td {
    @apply break-words border px-1 pb-2 pt-4 text-center;
  }

  .generic-content.wysiwyg p + h2,
  .generic-content.wysiwyg p + h3,
  .generic-content.wysiwyg p + h4,
  .generic-content.wysiwyg figure + h4,
  .generic-content.wysiwyg ul + h2,
  .generic-content.wysiwyg ul + h3 {
    @apply mt-6 lg:mt-12;
  }

  .generic-content.wysiwyg h1,
  .generic-content.wysiwyg h2,
  .generic-content.wysiwyg h3,
  .generic-content.wysiwyg h4,
  .generic-content.wysiwyg h5,
  .generic-content.wysiwyg h6 {
    @apply mb-1 mt-2 lg:mb-2 lg:mt-4;
  }

  #CybotCookiebotDialog #CybotCookiebotDialogBodyButtonsWrapper button {
    border-radius: 40px !important;
  }
  #CybotCookiebotDialog
    #CybotCookiebotDialogNav
    .CybotCookiebotDialogNavItemLink.CybotCookiebotDialogActive {
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
  }
}

.mapboxgl-ctrl-bottom-left .mapboxgl-ctrl-logo,
.mapboxgl-ctrl-bottom-right .mapboxgl-ctrl-attrib-inner {
  display: none;
}

/* Hide Google ReCaptcha V3 floating badge */
.grecaptcha-badge {
  visibility: hidden;
}
