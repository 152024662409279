@font-face {
  font-family: "Matter";
  src: url("/fonts/Matter-Regular.woff2") format("woff2"),
    url("/fonts/Matter-Regular.woff") format("woff");
  font-display: swap;
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "Matter";
  src: url("/fonts/Matter-RegularItalic.woff2") format("woff2"),
    url("/fonts/Matter-RegularItalic.woff") format("woff");
  font-display: swap;
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: "Reckless Neue";
  src: url("/fonts/RecklessNeue-Book.woff2") format("woff2"),
    url("/fonts/RecklessNeue-Book.woff") format("woff");
  font-display: swap;
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "Reckless Neue";
  src: url("/fonts/RecklessNeue-BookItalic.woff2") format("woff2"),
    url("/fonts/RecklessNeue-BookItalic.woff") format("woff");
  font-display: swap;
  font-style: italic;
  font-weight: 400;
}
